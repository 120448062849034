<template>
    <Button label="Novo Template" icon="pi pi-plus" @click="toInserir()" v-if="$temAcessoView('TEMPL-01')" />
</template>

<script>
export default {
    methods: {
        toInserir() {
            this.$router.push({
                name: `Templates_Inserir`,
            });
        },
    },
};
</script>
