<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-copy"></i> Template</h5>
                    <btn-refresh @click="obterTemplate()"></btn-refresh>
                </div>
                <div class="p-mb-4">
                    <detalhe titulo="Código">{{ template?.codigo }}</detalhe>
                    <detalhe titulo="Descrição">{{ template?.descricao }}</detalhe>
                    <detalhe titulo="Dados">{{ template?.dados }}</detalhe>
                    <detalhe titulo="Arquivo" v-if="temArquivo"
                        >{{ template?.nomeArquivo
                        }}<btn-download :template="template"></btn-download
                    ></detalhe>
                    <!-- <detalhe titulo="Conteúdo" v-if="!temArquivo"
                        ><Editor v-model="template.conteudo" :readonly="true" v-if="template"
                    /></detalhe> -->
                </div>
                <btn-atualizar :template="template"></btn-atualizar>
                <btn-inserir class="p-ml-2"></btn-inserir>
                <btn-voltar
                    :route="{
                        name: `Templates`,
                    }"
                ></btn-voltar>
            </div>
        </div>
    </div>
</template>

<script>
import TemplatesService from './services';
import BtnInserir from './BtnInserir';
import BtnAtualizar from './BtnAtualizar';
import BtnDownload from './BtnDownload';

export default {
    components: {
        BtnInserir,
        BtnAtualizar,
        BtnDownload,
    },

    data() {
        return {
            template: null,
        };
    },

    computed: {
        temArquivo() {
            return this.template && this.template.nomeArquivo;
        },
    },

    methods: {
        obterTemplate() {
            this.$store.dispatch('addRequest');
            TemplatesService.obterPorId(this.$route.params.id).then(response => {
                if (response && response.success) {
                    this.template = response.data;
                } else {
                    this.template = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterTemplate();
    },
};
</script>
